<template>
  <v-dialog
    v-model="matchCreatotDialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3 color3Text--text"
        v-on="on"
        :disabled="disabled"
        :small="small"
      >Add A Match to {{division.name}} - {{round.name}}</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Match Up Creator</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="matchCreatotDialog = false"
          :disabled="persist"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-1">
        <match-up-creator
          :day="day"
          :court="court"
          :time="time"
          :open="matchCreatotDialog"
          @loading-change="onLoadingChange"
          @saved="onSaved"
          :availSlots="availSlots"
          :roundId="roundId"
        ></match-up-creator>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MatchUpCreator from '@/components/Tournament/Courts/MatchUpCreatorWindows'
import { mapGetters } from 'vuex'

export default {
  props: ['day', 'court', 'time', 'open', 'availSlots', 'roundId', 'small', 'disabled'],
  data () {
    return {
      matchCreatotDialog: false,
      persist: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    division () {
      return this.roundId && this.tournament.getDivisionByRoundId(this.roundId)
    },
    round () {
      return this.roundId && this.division && this.division.days.find(f => f.id === this.roundId)
    }
  },
  methods: {
    onLoadingChange (v) {
      this.persist = v
    },
    onSaved () {
      this.matchCreatotDialog = false
      this.$emit('saved')
    }
  },
  components: {
    MatchUpCreator
  }
}
</script>
